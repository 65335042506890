import { isProduction } from '@pn/core/utils/env';
import { stackWellsElasticMapping } from '@pn/resources/elastic-mappings/stackdx/stack_wells';
import type { ElasticMappingItem } from '@pn/services/api/data/types';

export const wellsElasticMapping: ElasticMappingItem[] = [
  ...(isProduction() ? [] : stackWellsElasticMapping),
  {
    field: 'internal_id',
    sourceType: 'keyword',
    label: 'UWI',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 160,
  },
  {
    field: 'formatted_well_id',
    sourceType: 'keyword',
    label: 'Formatted UWI',
    folder: 'Well Header',
    width: 180,
  },
  {
    field: 'well_name',
    sourceType: 'text',
    label: 'Well Name',
    folder: 'Well Header',
  },
  {
    field: 'license_number',
    sourceType: 'text',
    label: 'Licence Number',
    folder: 'Well Header',
  },
  {
    field: 'province_license',
    sourceType: 'keyword',
    label: 'Province Licence',
    folder: 'Well Header',
  },
  {
    field: 'province',
    sourceType: 'text',
    label: 'Province',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 100,
  },
  {
    field: 'licensee',
    sourceType: 'text',
    label: 'Licensee',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 225,
  },
  {
    field: 'licensee_brand',
    sourceType: 'text',
    label: 'Licensee Brand',
    folder: 'Well Header',
    width: 250,
  },
  {
    field: 'licensee_provincial',
    sourceType: 'text',
    label: 'Licensee Provincial',
    folder: 'Well Header',
    width: 250,
  },
  {
    field: 'licensee_history',
    sourceType: 'text',
    label: 'Licensee History',
    folder: 'Well Header',
    width: 250,
    isNotSortable: true,
  },
  {
    field: 'operator',
    sourceType: 'text',
    label: 'Operator',
    folder: 'Well Header',
    width: 250,
  },
  {
    field: 'field_name',
    sourceType: 'text',
    label: 'Field',
    folder: 'Well Header',
    isShownByDefault: true,
  },
  {
    field: 'pool_name',
    sourceType: 'text',
    label: 'Pool',
    folder: 'Well Header',
  },
  {
    field: 'unit_name',
    sourceType: 'text',
    label: 'Unit',
    folder: 'Well Header',
  },
  {
    field: 'unit_agreement_id',
    sourceType: 'keyword',
    label: 'Unit Agreement',
    folder: 'Well Header',
  },
  {
    field: 'unit_status',
    sourceType: 'text',
    label: 'Unit Status',
    folder: 'Well Header',
  },
  {
    field: 'unit_operator',
    sourceType: 'text',
    label: 'Unit Operator',
    folder: 'Well Header',
  },
  {
    field: 'producing_formation',
    sourceType: 'text',
    label: 'Producing Formation',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 200,
  },
  {
    field: 'projected_formation',
    sourceType: 'text',
    label: 'Projected Formation',
    folder: 'Well Header',
  },
  {
    field: 'terminating_formation',
    sourceType: 'text',
    label: 'Terminating Formation',
    folder: 'Well Header',
    width: 200,
  },
  {
    field: 'intersecting_formations',
    sourceType: 'text',
    label: 'Intersecting Formations',
    folder: 'Well Header',
    width: 200,
    isNotSortable: true,
  },
  {
    field: 'spud_date',
    sourceType: 'date',
    label: 'Spud Date',
    folder: 'Well Header',
    isShownByDefault: true,
  },
  {
    field: 'license_date',
    sourceType: 'date',
    label: 'Licence Date',
    folder: 'Well Header',
  },
  {
    field: 'license_last_status_date',
    sourceType: 'date',
    label: 'Last Licence Status Date',
    folder: 'Well Header',
  },
  {
    field: 'wellbore_last_status_date',
    sourceType: 'date',
    label: 'Last Well Status Date',
    folder: 'Well Header',
  },
  {
    field: 'completion_date',
    sourceType: 'date',
    label: 'Completion Date',
    folder: 'Well Header',
    tooltip: 'Date of the most recent completion',
  },
  {
    field: 'initial_completion_date',
    sourceType: 'date',
    label: 'Initial Completion Date',
    folder: 'Well Header',
  },
  {
    field: 'on_production_date',
    sourceType: 'date',
    label: 'On Production Date',
    folder: 'Well Header',
  },
  {
    field: 'well_status',
    sourceType: 'text',
    label: 'Provincial Status',
    folder: 'Well Header',
  },
  {
    field: 'well_fluid',
    sourceType: 'text',
    label: 'Provincial Fluid',
    folder: 'Well Header',
  },
  {
    field: 'well_type',
    sourceType: 'text',
    label: 'Provincial Type',
    folder: 'Well Header',
  },
  {
    field: 'petro_ninja_well_status',
    sourceType: 'text',
    label: 'Petro Ninja Status',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 135,
  },
  {
    field: 'petro_ninja_well_fluid',
    sourceType: 'text',
    label: 'Petro Ninja Fluid',
    folder: 'Well Header',
  },
  {
    field: 'petro_ninja_well_type',
    sourceType: 'text',
    label: 'Petro Ninja Type',
    folder: 'Well Header',
  },
  {
    field: 'well_symbol_name',
    sourceType: 'text',
    label: 'Petro Ninja Symbol',
    folder: 'Well Header',
  },
  {
    field: 'license_status',
    sourceType: 'text',
    label: 'Licence Status',
    folder: 'Well Header',
  },
  {
    field: 'kb_elevation',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'KB Elevation',
    folder: 'Well Header',
  },
  {
    field: 'gl_elevation',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'Ground Elevation',
    folder: 'Well Header',
  },
  {
    field: 'tmd',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'TMD',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 120,
  },
  {
    field: 'tvd',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'TVD',
    folder: 'Well Header',
    isShownByDefault: true,
    width: 120,
  },
  {
    field: 'projected_depth_m',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'Projected Depth',
    folder: 'Well Header',
  },
  {
    field: 'plug_back_depth_m',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'Plug Back Depth',
    folder: 'Well Header',
  },
  {
    field: 'trajectory',
    sourceType: 'keyword',
    domainTypeAttributes: {
      options: ['Vertical', 'Horizontal', 'Slant', 'Directional'],
    },
    label: 'Trajectory',
    folder: 'Well Header',
  },
  {
    field: 'drilling_contractor',
    sourceType: 'text',
    label: 'Drilling Contractor',
    folder: 'Well Header',
  },
  {
    field: 'drilling_contractor_rigno',
    sourceType: 'keyword',
    label: 'Rig Number',
    folder: 'Well Header',
  },
  {
    field: 'rig_rlse_date',
    sourceType: 'date',
    label: 'Rig Release Date',
    folder: 'Well Header',
  },
  {
    field: 'surface_abandonment_date',
    sourceType: 'date',
    label: 'Surface Abandonment Date',
    folder: 'Well Header',
  },
  {
    field: 'zone_abandonment_date',
    sourceType: 'date',
    label: 'Zone Abandonment Date',
    folder: 'Well Header',
  },
  {
    field: 'groundwater_protection_formation',
    sourceType: 'text',
    label: 'Groundwater Protection Formation',
    folder: 'Well Header',
  },
  {
    field: 'lahee_class',
    sourceType: 'text',
    label: 'Lahee Class',
    folder: 'Well Header',
  },
  {
    field: 'head_lessor',
    sourceType: 'text',
    label: 'Head Lessor',
    folder: 'Well Header',
  },
  {
    field: 'base_of_groundwater_elevation_kb',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'KB Base of Groundwater Elevation',
    folder: 'Well Header',
  },
  {
    field: 'base_of_groundwater_elevation_asl',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'ASL Base of Groundwater Elevation',
    folder: 'Well Header',
  },
  {
    field: 'is_monobore',
    sourceType: 'boolean',
    label: 'Is Monobore',
    folder: 'Well Header',
  },
  {
    field: 'is_open_hole',
    sourceType: 'boolean',
    label: 'Is Open Hole',
    folder: 'Well Header',
  },
  {
    field: 'is_confidential',
    sourceType: 'boolean',
    label: 'Is Confidential',
    folder: 'Well Header',
  },
  {
    field: 'confidential_release_date',
    sourceType: 'date',
    label: 'Confidential Release Date',
    folder: 'Well Header',
  },
  {
    field: 'surface_latitude',
    sourceType: 'double',
    label: 'Surface Latitude',
    folder: 'Location',
  },
  {
    field: 'surface_longitude',
    sourceType: 'double',
    label: 'Surface Longitude',
    folder: 'Location',
  },
  {
    field: 'bottom_latitude',
    sourceType: 'double',
    label: 'Bottom Latitude',
    folder: 'Location',
  },
  {
    field: 'bottom_longitude',
    sourceType: 'double',
    label: 'Bottom Longitude',
    folder: 'Location',
  },
  {
    field: 'surface_location',
    sourceType: 'keyword',
    label: 'Surface DLS/NTS',
    folder: 'Location',
    isShownByDefault: true,
    width: 140,
  },
  {
    field: 'grid_system',
    sourceType: 'keyword',
    domainTypeAttributes: {
      options: ['DLS', 'NTS'],
    },
    label: 'Grid System',
    folder: 'Location',
  },
  {
    field: 'lsd',
    sourceType: 'integer',
    label: 'DLS LSD',
    folder: 'Location',
    tooltip: 'Surface location',
  },
  {
    field: 'sec',
    sourceType: 'integer',
    label: 'DLS Section',
    folder: 'Location',
    tooltip: 'Surface location',
  },
  {
    field: 'twn',
    sourceType: 'integer',
    label: 'DLS Township',
    folder: 'Location',
    tooltip: 'Surface location',
  },
  {
    field: 'range',
    sourceType: 'integer',
    label: 'DLS Range',
    folder: 'Location',
    tooltip: 'Surface location',
  },
  {
    field: 'meridian',
    sourceType: 'integer',
    label: 'DLS Meridian',
    folder: 'Location',
    tooltip: 'Surface location',
  },
  {
    field: 'nts_qtr_unit',
    sourceType: 'keyword',
    label: 'NTS QTR Unit',
    folder: 'Location',
  },
  {
    field: 'nts_unit',
    sourceType: 'integer',
    label: 'NTS Unit',
    folder: 'Location',
  },
  {
    field: 'nts_block',
    sourceType: 'keyword',
    label: 'NTS Block',
    folder: 'Location',
  },
  {
    field: 'nts_map',
    sourceType: 'integer',
    label: 'NTS Map',
    folder: 'Location',
  },
  {
    field: 'nts_map_sheet',
    sourceType: 'keyword',
    label: 'NTS Map Sheet',
    folder: 'Location',
  },
  {
    field: 'nts_grid',
    sourceType: 'integer',
    label: 'NTS Grid',
    folder: 'Location',
  },
  {
    field: 'has_recent_production',
    sourceType: 'boolean',
    label: 'Has Recent Production',
    folder: 'Production Summary',
  },
  {
    field: 'last_activity_date',
    sourceType: 'date',
    label: 'Last Activity Date',
    folder: 'Production Summary',
  },
  {
    field: 'last_production_date',
    sourceType: 'date',
    label: 'Last Production Date',
    folder: 'Production Summary',
  },
  {
    field: 'last_ngl_production_activity_month',
    sourceType: 'date',
    label: 'Last NGL Production Month',
    folder: 'Production Summary',
  },
  {
    field: 'last_injection_date',
    sourceType: 'date',
    label: 'Last Injection Date',
    folder: 'Production Summary',
  },
  {
    field: 'last_production_hours',
    sourceType: 'double',
    label: 'Last Production Hours',
    folder: 'Production Summary',
  },
  {
    field: 'last_injection_hours',
    sourceType: 'double',
    label: 'Last Injection Hours',
    folder: 'Production Summary',
  },
  {
    field: 'api_gravity',
    sourceType: 'double',
    label: 'API Gravity',
    folder: 'Production Summary',
  },
  {
    field: 'cumulative_oil',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Cumulative Oil',
    folder: 'Production Summary',
  },
  {
    field: 'cumulative_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3',
    },
    label: 'Cumulative Gas',
    folder: 'Production Summary',
  },
  {
    field: 'cumulative_water',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Cumulative Water',
    folder: 'Production Summary',
  },
  {
    field: 'cumulative_water_injection',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Cumulative Water Injection',
    folder: 'Production Summary',
  },
  {
    field: 'cumulative_steam_injection',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Cumulative Steam Injection',
    folder: 'Production Summary',
  },
  {
    field: 'cumulative_gas_injection',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3',
    },
    label: 'Cumulative Gas Injection',
    folder: 'Production Summary',
  },
  {
    field: 'cumulative_condensate',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Cumulative Condensate',
    folder: 'Production Summary',
  },
  {
    field: 'recent_oil',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Recent Oil',
    folder: 'Production Summary',
    isShownByDefault: true,
    width: 120,
  },
  {
    field: 'recent_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3',
    },
    label: 'Recent Gas',
    folder: 'Production Summary',
    isShownByDefault: true,
    width: 120,
  },
  {
    field: 'recent_water',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Recent Water',
    folder: 'Production Summary',
  },
  {
    field: 'recent_water_injection',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Recent Water Injection',
    folder: 'Production Summary',
  },
  {
    field: 'recent_steam_injection',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Recent Steam Injection',
    folder: 'Production Summary',
  },
  {
    field: 'recent_gas_injection',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3',
    },
    label: 'Recent Gas Injection',
    folder: 'Production Summary',
  },
  {
    field: 'recent_condensate',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Recent Condensate',
    folder: 'Production Summary',
  },
  {
    field: 'last_oil_rate',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Oil Rate',
    folder: 'Production Summary',
  },
  {
    field: 'last_gas_rate',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3/d',
    },
    label: 'Last Gas Rate',
    folder: 'Production Summary',
  },
  {
    field: 'last_water_rate',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Water Rate',
    folder: 'Production Summary',
  },
  {
    field: 'last_water_injection_rate',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Water Injection Rate',
    folder: 'Production Summary',
  },
  {
    field: 'last_condensate_rate',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3/d',
    },
    label: 'Last Condensate Rate',
    folder: 'Production Summary',
  },
  {
    field: 'ip3_oil',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'IP3 Oil',
    folder: 'Production Summary',
  },
  {
    field: 'ip3_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3',
    },
    label: 'IP3 Gas',
    folder: 'Production Summary',
  },
  {
    field: 'ip3_water',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'IP3 Water',
    folder: 'Production Summary',
  },
  {
    field: 'ip3_condensate',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'IP3 Condensate',
    folder: 'Production Summary',
  },
  {
    field: 'ip3_ngl',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'IP3 NGL',
    folder: 'Production Summary',
  },
  {
    field: 'ip12_oil',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'IP12 Oil',
    folder: 'Production Summary',
  },
  {
    field: 'ip12_gas',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'e3m3',
    },
    label: 'IP12 Gas',
    folder: 'Production Summary',
  },
  {
    field: 'ip12_water',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'IP12 Water',
    folder: 'Production Summary',
  },
  {
    field: 'ip12_condensate',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'IP12 Condensate',
    folder: 'Production Summary',
  },
  {
    field: 'ip12_ngl',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'IP12 NGL',
    folder: 'Production Summary',
  },
  {
    field: 'sample_date',
    sourceType: 'date',
    label: 'Sample Date',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_h2',
    sourceType: 'double',
    label: 'H2',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_h2s',
    sourceType: 'double',
    label: 'H2S',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_co2',
    sourceType: 'double',
    label: 'CO2',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_n2',
    sourceType: 'double',
    label: 'N2',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_he',
    sourceType: 'double',
    label: 'HE',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_c1',
    sourceType: 'double',
    label: 'C1',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_c2',
    sourceType: 'double',
    label: 'C2',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_c3',
    sourceType: 'double',
    label: 'C3',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_ic4',
    sourceType: 'double',
    label: 'iC4',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_nc4',
    sourceType: 'double',
    label: 'nC4',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_ic5',
    sourceType: 'double',
    label: 'iC5',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_nc5',
    sourceType: 'double',
    label: 'nC5',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_c6',
    sourceType: 'double',
    label: 'C6',
    folder: 'Gas Analysis',
  },
  {
    field: 'fs_c7_plus',
    sourceType: 'double',
    label: 'C7+',
    folder: 'Gas Analysis',
  },
  {
    field: 'relative_density',
    sourceType: 'double',
    label: 'Relative Density',
    folder: 'Gas Analysis',
  },
  {
    field: 'molecular_weight',
    sourceType: 'double',
    label: 'Molecular Weight',
    folder: 'Gas Analysis',
  },
  {
    field: 'gas_gross_heat_value_moisture_and_acid_gas_free',
    sourceType: 'double',
    label: 'Gross Heat Value (Moisture and Acid Gas Free)',
    folder: 'Gas Analysis',
  },
  {
    field: 'calculated_pcp_sampled',
    sourceType: 'double',
    label: 'Calculated PCP Sampled',
    folder: 'Gas Analysis',
  },
  {
    field: 'calculated_tcp_sampled',
    sourceType: 'double',
    label: 'Calculated TCP Sampled',
    folder: 'Gas Analysis',
  },
  {
    field: 'molecular_weight_of_c7_plus',
    sourceType: 'double',
    label: 'Molecular Weight of C7+',
    folder: 'Gas Analysis',
  },
  {
    field: 'number_of_stages',
    sourceType: 'integer',
    label: 'Number of Stages',
    folder: 'Completions Summary',
  },
  {
    field: 'total_water_volume_m3',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm3',
    },
    label: 'Total Water Volume',
    folder: 'Completions Summary',
  },
  {
    field: 'total_proppant',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'tonne', // should not be converted
    },
    label: 'Total Proppant',
    folder: 'Completions Summary',
  },
  {
    field: 'proppant_per_stage',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'tonne', // should not be converted
    },
    label: 'Proppant Per Stage',
    folder: 'Completions Summary',
  },
  {
    field: 'lateral_length',
    sourceType: 'double',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'm',
    },
    label: 'Lateral Length',
    folder: 'Completions Summary',
  },
  {
    field: 'average_interval_length',
    sourceType: 'double',
    label: 'Average Interval Length',
    folder: 'Completions Summary',
  },
  {
    field: 'frac_spacing',
    sourceType: 'double',
    label: 'Frac Spacing',
    folder: 'Completions Summary',
  },
  {
    field: 'has_complaint',
    sourceType: 'boolean',
    label: 'Has Complaint',
    folder: 'Compliance',
  },
  {
    field: 'has_spill',
    sourceType: 'boolean',
    label: 'Has Spill',
    folder: 'Compliance',
  },
  {
    field: 'has_surface_abandonment',
    sourceType: 'boolean',
    label: 'Has Surface Abandonment',
    folder: 'Compliance',
  },
  {
    field: 'has_casing_failure_history',
    sourceType: 'boolean',
    label: 'Has Casing Failure History',
    folder: 'Compliance',
  },
  {
    field: 'has_vent_flow_history',
    sourceType: 'boolean',
    label: 'Has Vent Flow History',
    folder: 'Compliance',
  },
  {
    field: 'has_open_vent_flow_case',
    sourceType: 'boolean',
    label: 'Has Open Vent Flow Case',
    folder: 'Compliance',
  },
  {
    field: 'has_open_casing_failure',
    sourceType: 'boolean',
    label: 'Has Open Casing Failure',
    folder: 'Compliance',
  },
  {
    field: 'has_base_of_groundwater_monitoring_flag',
    sourceType: 'boolean',
    label: 'Has Base of Groundwater Monitoring',
    folder: 'Compliance',
  },
  {
    field: 'has_gas_migration_flag',
    sourceType: 'boolean',
    label: 'Has Gas Migration',
    folder: 'Compliance',
  },
  {
    field: 'has_swab_well_flag',
    sourceType: 'boolean',
    label: 'Is Swab Well',
    folder: 'Compliance',
  },
  {
    field: 'has_orphan_flag',
    sourceType: 'boolean',
    label: 'Is Orphan Well',
    folder: 'Compliance',
  },
  {
    field: 'aer_d13_compliance',
    sourceType: 'keyword',
    label: 'AER-D13 Compliance',
    folder: 'Compliance',
  },
  {
    field: 'last_volumetric_activity_date',
    sourceType: 'date',
    label: 'Last Volumetric Activity Date',
    folder: 'Compliance',
  },
  {
    field: 'industry_reported_suspension_date',
    sourceType: 'date',
    label: 'Industry Reported Suspension Date',
    folder: 'Compliance',
  },
  {
    field: 'industry_reported_inspection_date',
    sourceType: 'date',
    label: 'Industry Reported Inspection Date',
    folder: 'Compliance',
  },
  {
    field: 'next_inspection_due_date',
    sourceType: 'date',
    label: 'Next Inspection Due Date',
    folder: 'Compliance',
  },
  {
    field: 'last_reported_downhole_operation',
    sourceType: 'text',
    label: 'Last Reported Downhole Operation',
    folder: 'Compliance',
  },
  {
    field: 'industry_reported_risk_class',
    sourceType: 'text',
    label: 'Industry Reported Risk Class',
    folder: 'Compliance',
  },
  {
    field: 'aer_deemed_risk_class',
    sourceType: 'text',
    label: 'AER-D13 Deemed Risk Class',
    folder: 'Compliance',
  },
  {
    field: 'medium_risk_type_6_well',
    sourceType: 'boolean',
    label: 'Medium Risk Type 6 Well',
    folder: 'Compliance',
  },
  {
    field: 'aer_approved_closure_plan_project_start_year',
    sourceType: 'integer',
    label: 'AER-D13 Approved Closure Plan Project Start Year',
    folder: 'Compliance',
  },
  {
    field: 'noncompliance_details',
    sourceType: 'text',
    label: 'Non-Compliance Details',
    folder: 'Compliance',
    isNotSortable: true,
  },
  {
    field: 'iwcp',
    sourceType: 'text',
    label: 'IWCP',
    folder: 'Compliance',
  },
  {
    field: 'aer_d13_exclusion_reason',
    sourceType: 'text',
    label: 'AER-D13 Exclusion Reason',
    folder: 'Compliance',
    isNotSortable: true,
  },
  {
    field: 'additional_information',
    sourceType: 'text',
    label: 'Additional Information',
    folder: 'Compliance',
    isNotSortable: true,
  },
  {
    field: 'has_digital_logs',
    sourceType: 'boolean',
    label: 'Has Digital Logs',
    folder: 'Data Availability',
  },
  {
    field: 'has_raster_logs',
    sourceType: 'boolean',
    label: 'Has Raster Logs',
    folder: 'Data Availability',
  },
  {
    field: 'has_gas_analysis',
    sourceType: 'boolean',
    label: 'Has Gas Analysis',
    folder: 'Data Availability',
  },
  {
    field: 'has_documents',
    sourceType: 'boolean',
    label: 'Has Documents',
    folder: 'Data Availability',
  },
  {
    field: 'has_oil_analysis',
    sourceType: 'boolean',
    label: 'Has Oil Analysis',
    folder: 'Data Availability',
  },
  {
    field: 'has_water_analysis',
    sourceType: 'boolean',
    label: 'Has Water Analysis',
    folder: 'Data Availability',
  },
  {
    field: 'has_pressure_data',
    sourceType: 'boolean',
    label: 'Has Pressure Data',
    folder: 'Data Availability',
  },
  {
    field: 'has_tour_report',
    sourceType: 'boolean',
    label: 'Has Tour Reports',
    folder: 'Data Availability',
  },
  {
    field: 'has_completions_report',
    sourceType: 'boolean',
    label: 'Has Completions Reports',
    folder: 'Data Availability',
  },
  {
    field: 'has_digital_directional_survey',
    sourceType: 'boolean',
    label: 'Has Digital Directional Survey',
    folder: 'Data Availability',
  },
  {
    field: 'has_pdf_directional_survey',
    sourceType: 'boolean',
    label: 'Has PDF Directional Survey',
    folder: 'Data Availability',
  },
  {
    field: 'has_core_data',
    sourceType: 'boolean',
    label: 'Has Core Data',
    folder: 'Data Availability',
  },
  {
    field: 'db_license_number',
    sourceType: 'integer',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'facility_id',
    sourceType: 'text',
    label: 'Facility IDs',
    folder: 'Connected Objects',
    isNotSortable: true,
  },
  {
    field: 'connected_facility_name',
    sourceType: 'text',
    label: 'Connected Facility Names',
    folder: 'Connected Objects',
    isNotSortable: true,
  },
  {
    field: 'connected_facility_sub_type',
    sourceType: 'text',
    label: 'Connected Facility Subtypes',
    folder: 'Connected Objects',
    isNotSortable: true,
  },
  {
    field: 'connected_facility_subcode',
    sourceType: 'text',
    label: 'Connected Facility Subcodes',
    folder: 'Connected Objects',
    isNotSortable: true,
  },
  {
    field: 'connected_facility_status',
    sourceType: 'text',
    label: 'Connected Facility Statuses',
    folder: 'Connected Objects',
    isNotSortable: true,
  },
  {
    field: 'connected_facility_licensee',
    sourceType: 'text',
    label: 'Connected Facility Licensees',
    folder: 'Connected Objects',
    isNotSortable: true,
  },
  {
    field: 'connected_facility_operator',
    sourceType: 'text',
    label: 'Connected Facility Operators',
    folder: 'Connected Objects',
    isNotSortable: true,
  },
  {
    field: 'well_id',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'pn_well_id',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'country',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'symbol',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'related_ids',
    sourceType: 'keyword',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'well_path_geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
  {
    field: 'geometry',
    sourceType: 'geo_shape',
    label: 'N/A',
    folder: 'N/A',
    isNotRenderable: true,
  },
];
