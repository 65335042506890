import { dependencies } from '@pn/core/dependencies';
import { type MappingItem } from '@pn/core/domain/data';
import { areFiltersApplied } from '@pn/core/domain/query';
import type { UnitSystem } from '@pn/core/domain/types';
import {
  getBeforeItemsLayerIds,
  isEmptyWorkspaceItem,
  isStreamableItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { dynamicDataProvider } from '@pn/core/providers/data/dynamicDataProvider';
import { workspaceActions } from '@pn/core/storage';
import { isWorkspaceItemCleared } from '@pn/core/storage/workspace/workspaceStorage';
import { createConcurrencyGuard } from '@pn/core/utils/concurrency';
import { generateNonce, log } from '@pn/core/utils/debug';
import { processLayers, syncVectorFeaturesState } from './layersProcessing';
import { getMapData, type MapData } from './mapData';
import { getTableData, type TableData } from './tableData';

const concurrencyGuard = createConcurrencyGuard<{ autoTrigger: boolean }>();

export async function visualizeLoadedWorkspaceItem(params: {
  item: WorkspaceItem;
  beforeItems: WorkspaceItem[];
  pageSize: number;
  unitSystem: UnitSystem;
  extraOptions: {
    autoTrigger?: boolean;
    tableOnly?: boolean;
    silent?: boolean;
  };
}): Promise<{
  tableData: TableData;
  mapData: MapData;
  mapping: MappingItem[];
}> {
  const {
    item,
    beforeItems,
    pageSize,
    unitSystem,
    extraOptions: { autoTrigger = false, tableOnly = false, silent = false },
  } = params;

  const guard = concurrencyGuard.get(item.id);
  if (guard?.isRunning) {
    if (autoTrigger && !guard?.autoTrigger) return exitEarly(item.id, true);

    log.info('🛑 concurrency guard triggered', item.id, guard);
    log.timeEnd(`visualizeWorkpaceItem [${item.id}]`);
    log.endGroup(`visualizeWorkpaceItem [${item.id}]`);
  }

  const currentGuardIndex = concurrencyGuard.start(item.id, { autoTrigger });

  log.startGroup(`visualizeWorkpaceItem [${item.id}]`);
  log.time(`visualizeWorkpaceItem [${item.id}]`);
  log.info('workspaceItem visualized', item);

  if (
    item.itemType === 'drawing' ||
    item.id === '_background' ||
    item.id === 'grids' // Grids source layer is non-interactable
  ) {
    return exitEarly(item.id);
  }

  if (item.itemType === 'annotation') {
    log.info('[special] visualizing annotation');

    const beforeLayerIds = getBeforeItemsLayerIds(beforeItems);
    dependencies.map.addAnnotationLayer(item, beforeLayerIds);

    return exitEarly(item.id);
  }

  if (isEmptyWorkspaceItem(item)) {
    log.info('[special] skipping empty workspace item');

    dependencies.notificationService.notify(
      'Unable to visualize an empty layer',
      'warning'
    );

    return exitEarly(item.id);
  }

  try {
    const mapping = await dynamicDataProvider(item.dataSource).getDataMapping(
      item
    );

    if (concurrencyGuard.isOutdated(item.id, currentGuardIndex)) {
      return exitEarly(item.id, true);
    }

    /**
     * Fetch both table and map data in parallel.
     */
    const nonce = generateNonce();
    log.time(`retrieved table & map data [${item.id}]`, nonce);
    const [tableData, mapData] = await Promise.all([
      getTableData({ item, mapping, pageSize }),
      getMapData({ item, mapping, silent }),
    ]);
    log.timeEnd(`retrieved table & map data [${item.id}]`, nonce);
    log.info('tableData', tableData, '\nmapData', mapData);

    if (concurrencyGuard.isOutdated(item.id, currentGuardIndex)) {
      return exitEarly(item.id, true);
    }

    if (!tableOnly && !isWorkspaceItemCleared(item.id)) {
      processLayers(item, beforeItems, {
        mapDataItems: mapData.data,
        unitSystem,
      });

      if (isStreamableItem(item)) {
        syncVectorFeaturesState(
          item,
          new Set(mapData.data.map(({ _id }) => _id))
        );
      }
    }

    /**
     * Put table and map data into the storage.
     */
    workspaceActions().markAsRendered(item.id);
    workspaceActions().updateCounts(item.id, {
      totalCount: tableData.totalCount,
      streamedCount: mapData.data.length,
    });
    if (areFiltersApplied(item.query.filters)) {
      workspaceActions().updateNumberOfElements(item.id, tableData.totalCount);
    }
    workspaceActions().updateTableDataItems(item.id, tableData.data);
    if (!item.isMappingInitialized) {
      workspaceActions().updateMapping(item.id, mapping);
    }

    log.timeEnd(`visualizeWorkpaceItem [${item.id}]`);
    log.endGroup(`visualizeWorkpaceItem [${item.id}]`);

    concurrencyGuard.stop(item.id);

    return { tableData, mapData, mapping };
  } catch (error) {
    handleError({
      error,
      userFriendlyMessage: `Failed to visualize ${item.name}`,
    });

    return exitWithError(item.id);
  }
}

/**
 * Bypass loading state and process empty table and map data.
 */
function exitEarly(
  id: WorkspaceItem['id'],
  guardTriggered = false
): {
  tableData: TableData;
  mapData: MapData;
  mapping: MappingItem[];
} {
  if (!guardTriggered) {
    workspaceActions().markAsProcessed(id);
    workspaceActions().markAsRendered(id);
    workspaceActions().updateCounts(id, {
      totalCount: 0,
      streamedCount: 0,
    });
    workspaceActions().updateTableDataItems(id, []);

    log.timeEnd(`visualizeWorkpaceItem [${id}]`);
    log.endGroup(`visualizeWorkpaceItem [${id}]`);
  }

  concurrencyGuard.stop(id);

  return {
    tableData: { data: [], totalCount: 0, timestamp: 0 },
    mapData: { data: [], timestamp: 0 },
    mapping: [],
  };
}

function exitWithError(id: WorkspaceItem['id']): {
  tableData: TableData;
  mapData: MapData;
  mapping: MappingItem[];
} {
  workspaceActions().markAsError(id);
  workspaceActions().updateCounts(id, {
    totalCount: 0,
    streamedCount: 0,
  });
  workspaceActions().updateTableDataItems(id, []);

  log.timeEnd(`visualizeWorkpaceItem [${id}]`);
  log.endGroup(`visualizeWorkpaceItem [${id}]`);

  concurrencyGuard.stop(id);

  return {
    tableData: { data: [], totalCount: 0, timestamp: 0 },
    mapData: { data: [], timestamp: 0 },
    mapping: [],
  };
}
